.carousel-root {
  width: 80%;
  margin: 0 auto;
}

.carousel .control-next.control-arrow::before {
  border-left: 8px solid #818181 !important;
}

.carousel .control-prev.control-arrow::before {
  border-right: 8px solid #818181 !important;
}
