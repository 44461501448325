body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #e5e5e5;
  --main-background-color: #ffffff;
  --tertiary-color: #848484;
  background-color: var(--main-background-color);
}

body[data-theme='light'] {
  --primary-color: #222222;
  --secondary-color: #ffffff;
  --main-background-color: #ffffff;
}
body[data-theme='dark'] {
  --primary-color: #ffffff;
  --secondary-color: #222222;
  --main-background-color: #222222;
}

a {
  text-decoration: none;
  transition: 250ms linear;
}

.portfolio {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--primary-color);
}

.container {
  max-width: 1220px;
  width: 82%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.page {
  padding-top: 16px;
  flex-grow: 1;
}

.page h1,
h3 {
  line-height: 1.1em;
}

.page p {
  font-size: 16px;
  line-height: 1.5em;
}

.page .page-header h1 {
  font-style: italic;
}

.page h3 {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 1.5em;
  color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .page {
    padding-top: 60px;
  }
}
