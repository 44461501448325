.project-content h3 {
  margin-bottom: 30px;
  color: var(--primary-color);
  border-bottom: 1px solid gray;
}

.project-content h1 {
  font-size: 24px;
  font-weight: 600;
}

.project-content {
  line-height: 1.5;
  font-size: 1.3em;
}

.project-content ul,
.project-content p {
  margin-bottom: 30px;
}

.project-content ul {
  list-style: circle;
  padding: 0 1.4em;
}

.project-content li {
  margin-bottom: 3px;
  line-height: 1.65em;
  color: var(--primary-color);
}

.carousel .thumbs-wrapper {
  margin-left: 0;
}

.carousel img {
  height: 100%;
}

.carousel .slide {
  height: 100%;
  background: transparent;
}

.carousel .control-next.control-arrow::before {
  border-left: 8px solid #222;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #222;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.btn-view-container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-navigate-container {
  margin-top: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tag-btn {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.tag-btn:hover {
  background-color: var(--primary-color);
  color: var(--main-background-color);
}

.tag-btn:focus {
  outline: none;
}

.page-header-title h2 {
  margin-top: 40px;
  line-height: calc(50px + 40%);
  font-size: 50px;
  color: var(--primary-color);
}

#project-markdown p {
  font-size: 20px;
}

#project-markdown a,
#project-markdown a:visited {
  color: var(--primary-color);
  opacity: 0.7;
  border-bottom: 1px solid var(--primary-color);
  transition: 250ms linear;
}
