.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.full {
  transition: opacity 250ms ease 0ms;
}

/* .thumb {
  border-radius: 5px;
  opacity: 0.5;
  background: var(--primary-color);
}

.thumb::before {
  background: #ffd772;
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: shimmer 3s linear infinite;
  transform: translateX(-100%);
  box-shadow: 5px 5px 15px 5px rgba(255, 208, 89, 0.33);
}

@keyframes shimmer {
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
} */
