.image-container {
  position: relative;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
  display: block;
  width: 100%;
}

#image-layer-text { 
  position: relative; 
  top: 35px;
  color: var(--primary-color) ;
}

#image-layer-text::first-letter { 
  text-transform: capitalize;
}
